import Mustache from "mustache";
import {saveAs} from 'file-saver';

import {MDCCheckbox} from '@material/checkbox';
import {MDCDialog} from '@material/dialog';
import {MDCMenu} from '@material/menu';
import {MDCList} from '@material/list';
import {MDCSelect} from '@material/select';
import {MDCTextField} from "@material/textfield";
import {MDCTopAppBar} from '@material/top-app-bar';

import {Router} from "./app";
import {ApiResponseCode, login, logout, getCurrentUser, getCurrentUserRoles, getUser, getPersons,
    getDuties, createDuty, getDuty, updateDuty, deleteDuty, getDutyTypes, getDutyLocations,
    downloadReport} from "./api";
import {ResponseCode} from "./const";
import {ErrorMessage, getErrorMessageForCode} from "./error";
import {parseNumber, formatNumber, getAccessToken, setAccessToken, removeAccessToken, fromDateString,
    toDateString, getReadableDateString, getReadableTimeString, getReadableDateTimeString} from "./util";

const PAGE_SIZE = 20;
const PAGE_OVERLAP = 2;

// --- Base controller ---

class Controller {

    /** @type {Router} */
    router;
    /** @type {HTMLElement} */
    container;

    /**
     * @param {Router} router
     * @param {HTMLElement} container
     */
    constructor(router, container) {
        this.router = router;
        this.container = container;
    }

    show() {

    }

    /**
     * @protected
     * @param {string} name
     * @returns Promise<string>
     */
    loadTemplate(name) {
        return new Promise(function (resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", "/tmpl/" + name + ".html", true);
            xhr.onload = function() {
                if (xhr.readyState == 4 && xhr.status >= 0) {
                    if (xhr.status == 200) {
                        const t = xhr.responseText;
                        Mustache.parse(t);
                        resolve(t);
                    } else {
                        console.error("Could not load tempate '" + name + "'! (Status code:" +
                            xhr.status + ")");
                        reject(ResponseCode.ERROR_UNKNOWN);
                    }
                }
            };
            xhr.onerror = function() {
                console.error("Could not load tempate '" + name + "'!");
                reject(ResponseCode.ERROR_NETWORK);
            };
            xhr.send(null);
        });
    }

    /**
     * @protected
     * @param {string} template
     * @param {any} view
     */
    renderTemplate(template, view) {
        const html = Mustache.render(template, view);
        setHtml(this.container, html);
    }

    /**
     * @protected
     * @returns Promise<any>
     */
    loadCurrentUser() {
        return getCurrentUser(getAccessToken());
    }

    /**
     * @protected
     * @returns Promise<any>
     */
    loadCurrentUserRoles() {
        return getCurrentUserRoles(getAccessToken());
    }

    /**
     * @protected
     * @param {number} userId
     * @returns Promise<any>
     */
    loadUser(userId) {
        if (userId == null) {
            return new Promise((resolve) => resolve(null));
        }

        return new Promise((resolve, reject) => {
            getUser(getAccessToken(), userId)
                .then(u => resolve(u))
                .catch(e => {
                    if (e == ApiResponseCode.ERROR_LOGIC_USER_NOT_FOUND) {
                        resolve(null);
                    } else {
                        reject(e);
                    }
                });
        });
    }

    /**
     * @protected
     * @returns Promise<any>
     */
    loadPersons() {
        return getPersons(getAccessToken());
    }

    /**
     * @protected
     * @param {number} offset
     * @param {number} limit
     * @returns Promise<any>
     */
    loadDuties(offset, limit) {
        return getDuties(getAccessToken(), offset, limit);
    }

    /**
     * @protected
     * @param {number} dutyId
     * @returns Promise<any>
     */
    loadDuty(dutyId) {
        return getDuty(getAccessToken(), dutyId);
    }

    /**
     * @protected
     * @returns Promise<any>
     */
    loadDutyTypes() {
        return getDutyTypes(getAccessToken());
    }

    /**
     * @protected
     * @returns Promise<any>
     */
    loadDutyLocations() {
        return getDutyLocations(getAccessToken());
    }

    /**
     * @protected
     * @param {any} a
     * @param {any} b
     * @return number
     */
    compareByPosition(a, b) {
        if (a.position < b.position) {
            return -1;
        } else if (a.position > b.position) {
            return 1;
        } else {
            return 0;
        }
    }

    /**
     * @protected
     * @param {number} code
     */
    handleError(code) {
        if (code == ResponseCode.ERROR_NETWORK) {
            return;
        }

        if (code == ApiResponseCode.ERROR_AUTH_UNKNOWN ||
            code == ApiResponseCode.ERROR_AUTH_UNAUTHORIZED) {
            console.info("Invalid token!");
            removeAccessToken();
            this.router.navigateLogin();
            return;
        }

        const message = getErrorMessageForCode(code);
        setHtml(this.container, "<p>" + message + "</p>");
    }

}

// --- Login controller ---

class LoginController extends Controller {

    /** @type {HTMLElement} */
    errorText;
    /** @type {HTMLElement} */
    usernameInput;
    /** @type {HTMLElement} */
    passwordInput;

    /** 
     * @param {Router} router
     * @param {HTMLElement} container
     */
    constructor(router, container) {
        super(router, container);
    }

    async show() {
        try {
            const template = await this.loadTemplate("login");
            this.renderTemplate(template, this.createViewModel());
            this.initViews();
        } catch (e) {
            console.log(e.stack);
            this.handleError(e);
        }
    }

    /**
     * @private
     * @returns any
     */
    createViewModel() {
        const viewModel = {};
        viewModel.texts = this.createTextsViewModel();
        return viewModel;
    }

    /**
     * @private
     * @returns any
     */
    createTextsViewModel() {
        const t = {};
        t.title = texts.orgName;
        t.subTitle = texts.appName;
        t.labelUsername = texts.labelUsername;
        t.labelPassword = texts.labelPassword;
        t.actionLogin = texts.actionLogin;
        return t;
    }

    /**
     * @private
     */
    initViews() {
        this.errorText = getElement("text-error");

        new MDCTextField(document.getElementById("text-field-username"));
        this.usernameInput = getElement("text-field-input-username");

        new MDCTextField(document.getElementById("text-field-password"));
        this.passwordInput = getElement("text-field-input-password");

        const loginButton = getElement("button-login");
        addClickListener(loginButton, () => this.executeLogin());
    }

    /**
     * @private
     */
    async executeLogin() {
        this.removeErrorMessage();

        // @ts-ignore
        const username = this.usernameInput.value;
        // @ts-ignore
        const password = this.passwordInput.value;

        if (username == "" || password == "") {
            this.showErrorMessage(texts.errorAuthCredentialsEmpty);
            return;
        }

        try {
            const token = await login(username, password);
            this.handleLoginSuccess(token);
        } catch (e) {
            console.log(e.stack);
            this.handleLoginError(e);
        }
    }
    
    /**
     * @private
     * @param {string} token
     */
    handleLoginSuccess(token) {
        console.info("Login success: token=" + token);
        setAccessToken(token);
        this.router.navigateListDuties();
    }

    /**
     * @private
     * @param {number} code
     */
    handleLoginError(code) {
        if (code == ResponseCode.ERROR_NETWORK) {
            return;
        }

        console.info("Login error: code=" + code);
        const message = getErrorMessageForCode(code);
        this.showErrorMessage(message);
    }

    /**
     * @private
     */
    removeErrorMessage() {
        setHtml(this.errorText, "");
    }

    /**
     * @private
     * @param {string} errorMessage
     */
    showErrorMessage(errorMessage) {
        setHtml(this.errorText, errorMessage);
    }

}

// --- List duties controller ---

class ListDutiesController extends Controller {

    /** @type {any} */
    user;
    /** @type {boolean} */
    hasUserEditorRole;
    /** @type {boolean} */
    hasUserReporterRole;

    /** @type {any} */
    dutyTypes;
    /** @type {any} */
    dutyLocations;

    /** @type {number} */
    offset = 0;
    /** @type {any} */
    dutyIds = [];

    /** @type {string} */
    dutyTemplate;

    /** @type {HTMLElement} */
    dutiesContainer;

    /** 
     * @param {Router} router
     * @param {HTMLElement} container
     */
    constructor(router, container) {
        super(router, container);
    }

    async show() {
        try {
            this.user = await this.loadCurrentUser();
            const userRoles = await this.loadCurrentUserRoles();
            this.hasUserEditorRole = userRoles.includes("editor") || userRoles.includes("admin");
            this.hasUserReporterRole = userRoles.includes("reporter") || userRoles.includes("admin");

            const dutyTypes = await this.loadDutyTypes();
            this.dutyTypes = createMap(dutyTypes);
            const dutyLocations = await this.loadDutyLocations();
            this.dutyLocations = createMap(dutyLocations);

            const duties = await this.loadDuties(0, PAGE_SIZE);
            this.offset = duties.items.length;

            const viewModel = this.createViewModel(duties.total);

            this.dutyTemplate = await this.loadTemplate("duty");

            const template = await this.loadTemplate("list-duties");
            this.renderTemplate(template, viewModel);
            this.initViews();

            this.addDuties(duties.items);
        } catch (e) {
            console.log(e);
            this.handleError(e);
        }
    }

    /**
     * @private
     * @param {number} dutiesCount
     * @returns any
     */
    createViewModel(dutiesCount) {
        const viewModel = {};
        viewModel.texts = this.createTextsViewModel();
        viewModel.dutiesCount = dutiesCount;
        return viewModel;
    }

    /**
     * @private
     * @returns any
     */
    createTextsViewModel() {
        const t = {};
        t.title = texts.viewTitleDutyList;
        t.actionMore = texts.actionMore;
        t.actionLogout = texts.actionLogout;
        t.actionCreateDuty = texts.actionCreateDuty;
        t.actionEditDuty = texts.actionEditDuty;
        t.actionCreateReport = texts.actionCreateReport;
        t.dialogTitleCreateReport = texts.dialogTitleCreateReport;
        t.dialogMessageCreateReport = texts.dialogMessageCreateReport;
        t.dialogActionCreateReportNo = texts.dialogActionCreateReportNo;
        t.dialogActionCreateReportYes = texts.dialogActionCreateReportYes;
        t.noDuties = texts.textNoDuties;
        return t;
    }

    /**
     * @private
     */
    initViews() {
        new MDCTopAppBar(getElement("app-bar"));

        const moreMenu = new MDCMenu(getElement("app-bar-menu-more"));
        moreMenu.setAnchorMargin({top: 18, bottom: 0, left: 0, right: 18});
        
        const moreButton = getElement("app-bar-button-more");
        addClickListener(moreButton, () => moreMenu.open = !moreMenu.open);

        const createReportDialog = this.createCreateReportDialog();
        const createReportButton = getElement("app-bar-button-create-report");
        createReportButton.hidden = !this.hasUserReporterRole;
        addClickListener(createReportButton, () => {
            createReportButton.blur();
            createReportDialog.open();
        });

        const logoutButton = getElement("app-bar-button-logout");
        addClickListener(logoutButton, () => this.executeLogout());

        this.dutiesContainer = getElement("container-duties");
        if (this.dutiesContainer != null) {
            const list = new MDCList(this.dutiesContainer);
            addListItemClickListener(list, (id) => this.viewDuty(id));
            addListItemActionClickListener(list, (id) => this.editDuty(id));
        }

        const scrollDetectionContainer = getElement("container-scroll-detection");
        const intersectionObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.loadMoreDuties();
                }
            });
        });
        intersectionObserver.observe(scrollDetectionContainer);
    }

    /**
     * @private
     */
    async loadMoreDuties() {
        const offset = this.offset > PAGE_OVERLAP ? this.offset - PAGE_OVERLAP : 0;
        const duties = await this.loadDuties(offset, PAGE_SIZE);
        
        const distinctDuties = [];
        duties.items.forEach(duty => {
            if (!this.dutyIds.includes(duty.id)) {
                distinctDuties.push(duty);
            }
        });
        
        this.offset = this.offset + distinctDuties.length;

        this.addDuties(distinctDuties);
    }

    /**
     * @private
     * @param {any} duties
     */
    addDuties(duties) {
        duties.forEach(duty => {
            this.dutyIds.push(duty.id);
            const viewModel = this.createDutyViewModel(duty);
            const html = Mustache.render(this.dutyTemplate, viewModel);
            appendHtml(this.dutiesContainer, html);
        });
    }

    /**
     * @private
     * @param {any} duty
     * @returns any
     */
    createDutyViewModel(duty) {
        const dt = this.dutyTypes.get(duty.typeId);
        const dl = this.dutyLocations.get(duty.locationId);

        const d = {};
        d.id = duty.id;
        d.date = getReadableDateString(fromDateString(duty.date));
        d.typeDesc = getObjectFieldValue(dt, "description", texts.textUnknown);
        d.typeAbbr = getObjectFieldValue(dt, "abbreviation", "?");
        d.typeColor = getObjectFieldValue(dt, "color", "#FF0000");
        d.locationDesc = getObjectFieldValue(dl, "description", texts.textUnknown);
        d.canBeChanged = this.hasUserEditorRole || duty.createdBy == this.user.id;
        return d;
    }

    /**
     * @private
     */
    async executeLogout() {
        try {
            await logout(getAccessToken());
            this.handleLogoutSuccess();
        } catch (e) {
            console.log(e.stack);
            this.handleLogoutError(e);
        }
    }
    
    /**
     * @private
     */
    handleLogoutSuccess() {
        console.info("Logout success.");
        removeAccessToken();
        this.router.navigateLogin();
    }

    /**
     * @private
     * @param {number} code
     */
    handleLogoutError(code) {
        if (code == ResponseCode.ERROR_NETWORK) {
            return;
        }

        console.info("Logout error: code=" + code);
        const message = getErrorMessageForCode(code);
        setHtml(this.container, "<p>" + message + "</p>");
    }

    /**
     * @private
     * @param {number} id
     */
    viewDuty(id) {
        this.router.navigateViewDuty(id);
    }

    /**
     * @private
     * @param {number} id
     */
    editDuty(id) {
        this.router.navigateEditDuty(id);
    }
    
    /**
     * @private
     * @returns any
     */
    createCreateReportDialog() {
        const dialog = new MDCDialog(getElement("dialog-create-report"));

        const yearSelect = getElement("dialog-create-report-select-input-year")
        removeAllChilds(yearSelect);
        const currentYear = new Date().getFullYear();
        const minYear = currentYear - 10;
        for (var year = currentYear; year >= minYear; year--) {
            const y = year.toString();
            addSelectOption(yearSelect, y, y, year == currentYear);
        }

        const dialogButtonCancel = getElement("dialog-button-create-report-cancel");
        addClickListener(dialogButtonCancel, () => {
            dialog.close();
        });
        const dialogButtonOk = getElement("dialog-button-create-report-ok");
        addClickListener(dialogButtonOk, () => {
            // @ts-ignore
            const year = yearSelect.value;
            this.executeCreateReport(year)
            dialog.close();
        });

        return dialog;
    }

    /**
     * @private
     * @param {number} year
     */
    async executeCreateReport(year) {
        console.info("Creating report for: " + year);
        try {
            const response = await downloadReport(getAccessToken(), year);
            this.handleCreateReportSuccess(year, response);
        } catch (e) {
            console.log(e.stack);
            this.handleCreateReportError(year, e);
        }
    }
    
    /**
     * @private
     * @param {number} year
     * @param {any} response
     */
    handleCreateReportSuccess(year, response) {
        const filename = texts.orgName + " - " + texts.filenameReport + " " + year + ".xlsx";
        saveAs(response, filename);
    }

    /**
     * @private
     * @param {number} year
     * @param {number} code
     */
    handleCreateReportError(year, code) {
        if (code == ResponseCode.ERROR_NETWORK) {
            return;
        }

        console.info("Create report error: code=" + code);
        const message = getErrorMessageForCode(code);
        setHtml(this.container, "<p>" + message + "</p>");
    }

}

// --- Duty controller ---

class DutyController extends Controller {

    /** @type {HTMLElement} */
    errorText;

    /**
     * @protected
     */
    initViews() {
        this.errorText = getElement("text-error");
    }

    /**
     * @protected
     * @param {any} duty
     * @returns boolean
     */
    hasDutyIndividualTimes(duty) {
        if (duty.persons.length <= 1) {
            return false;
        }
        const startDate = duty.persons[0].start;
        const endDate = duty.persons[0].end;
        for (var dutyPerson of duty.persons) {
            if (dutyPerson.start != startDate || dutyPerson.end != endDate) {
                return true;
            }
        }
        return false;
    }

    /**
     * @protected
     * @param {string} message
     */
    showErrorMessage(message) {
        setHtml(this.errorText, message);
        this.errorText.hidden = false;
    }

    /**
     * @protected
     */
    removeErrorMessage() {
        setHtml(this.errorText, "");
        this.errorText.hidden = true;
    }

}

// --- Create/edit duty controller ---

class CreateEditDutyController extends DutyController {
    
    /** @type {any} */
    dutyTypes;
    /** @type {any} */
    dutyLocations;
    /** @type {any} */
    persons;
    /** @type {any} */
    duty;

    /** @type {string} */
    dutyPersonTemplate;

    /** @type {HTMLElement} */
    dutyTypeSelect;
    /** @type {HTMLElement} */
    dutyLocationSelect;
    /** @type {HTMLElement} */
    dutyDateInput;
    /** @type {HTMLElement} */
    dutyTimesContainer;
    /** @type {HTMLElement} */
    dutyStartTimeInput;
    /** @type {HTMLElement} */
    dutyEndTimeInput;
    /** @type {HTMLElement} */
    dutyIndividualTimesInput;
    /** @type {HTMLElement} */
    dutyPersonsContainer;
    /** @type {HTMLElement} */
    dutyCommentInput;

    /** @type {boolean} */
    individualTimes = false;

    /** 
     * @param {Router} router
     * @param {HTMLElement} container
     */
    constructor(router, container) {
        super(router, container);
    }

    /**
     * @protected
     * @param {any} persons
     * @returns any
     */
    createPersonsViewModel(persons) {
        const ps = [];
        persons.items.forEach(person => {
            const p = {};
            p.id = person.id;
            p.name = createPersonName(person);
            ps.push(p);
        });
        return ps;
    }

    /**
     * @protected
     */
    initViews() {
        super.initViews();

        new MDCSelect(getElement("select-duty-type"));
        this.dutyTypeSelect = getElement("select-input-duty-type");
        addChangeListener(this.dutyTypeSelect, () => {
            // @ts-ignore
            const dutyTypeId = this.dutyTypeSelect.value;
            // @ts-ignore
            const dutyLocationId = this.dutyLocationSelect.value;
            this.updateDutyLocations(dutyTypeId, dutyLocationId);
        });

        new MDCSelect(getElement("select-duty-location"));
        this.dutyLocationSelect = getElement("select-input-duty-location");

        new MDCTextField(document.getElementById("text-field-duty-date"));
        this.dutyDateInput = getElement("text-field-input-duty-date");

        this.dutyTimesContainer = getElement("container-duty-times");

        new MDCTextField(document.getElementById("text-field-duty-start-time"));
        this.dutyStartTimeInput = getElement("text-field-input-duty-start-time");
        addChangeListener(this.dutyStartTimeInput, () => {
            // @ts-ignore
            const dutyStartTime = this.dutyStartTimeInput.value;
            this.updateDutyPersonStartTimes(dutyStartTime);
        });

        new MDCTextField(document.getElementById("text-field-duty-end-time"));
        this.dutyEndTimeInput = getElement("text-field-input-duty-end-time");
        addChangeListener(this.dutyEndTimeInput, () => {
            // @ts-ignore
            const dutyEndTime = this.dutyEndTimeInput.value;
            this.updateDutyPersonEndTimes(dutyEndTime);
        });

        new MDCCheckbox(getElement("checkbox-individual-duty-times"));
        this.dutyIndividualTimesInput = getElement("checkbox-input-individual-duty-times");
        addChangeListener(this.dutyIndividualTimesInput, () => {
            this.individualTimes = !this.individualTimes;
            this.updateDutyTimeInputs();
            this.updateDutyPersonTimeInputs();
        });

        this.dutyPersonsContainer = getElement("container-duty-persons");

        new MDCTextField(document.getElementById("text-field-duty-comment"));
        this.dutyCommentInput = getElement("text-field-input-duty-comment");
    }

    /**
     * @protected
     * @param {number} dutyTypeId
     */
    updateDutyTypes(dutyTypeId) {
        removeAllChilds(this.dutyTypeSelect);
        for (var dutyType of this.dutyTypes) {
            addSelectOption(this.dutyTypeSelect, dutyType.id, dutyType.description,
                dutyType.id == dutyTypeId);
        }
    }

    /**
     * @protected
     */
    updateDutyTimeInputs() {
        this.dutyTimesContainer.hidden = this.individualTimes;
    }

    /**
     * @protected
     */
    updateDutyIndividualTimesInput() {
        // @ts-ignore
        this.dutyIndividualTimesInput.checked = this.individualTimes;
    }

    /**
     * @protected
     * @param {number} dutyTypeId
     * @param {number} dutyLocationId
     */
    updateDutyLocations(dutyTypeId, dutyLocationId) {
        removeAllChilds(this.dutyLocationSelect);
        if (this.dutyTypes.length == 0) {
            return;
        }
        var dutyType = this.dutyTypes.find(dt => dt.id == dutyTypeId);
        if (dutyType == null) {
            dutyType = this.dutyTypes[0];
        }
        for (var dutyLocation of this.dutyLocations) {
            if (dutyType.locationIds.includes(dutyLocation.id)) {
                addSelectOption(this.dutyLocationSelect, dutyLocation.id, dutyLocation.description,
                    dutyLocation.id == dutyLocationId);
            }
        }
    }

    /**
     * @protected
     * @param {number} number
     * @param {string} name
     * @param {Date} startDate
     * @param {Date} endDate
     */
    addDutyPerson(number, name, startDate, endDate) {
        const viewModel = this.createDutyPersonViewModel(number);

        const html = Mustache.render(this.dutyPersonTemplate, viewModel);
        appendHtml(this.dutyPersonsContainer, html);

        const nameInput = getElement("text-field-input-duty-person-" + number + "-name");
        if (name != null) {
            // @ts-ignore
            nameInput.value = name;
        }
        new MDCTextField(document.getElementById("text-field-duty-person-" + number + "-name"));

        const timesContainer = getElement("container-duty-person-" + number + "-times");
        timesContainer.hidden = !this.individualTimes;

        const startTimeInput = getElement("text-field-input-duty-person-" + number + "-start-time");
        if (startDate != null) {
            // @ts-ignore
            startTimeInput.value = getTimeValueFromDate(startDate);
        }
        new MDCTextField(document.getElementById("text-field-duty-person-" + number + "-start-time"));

        const endTimeInput = getElement("text-field-input-duty-person-" + number + "-end-time");
        if (endDate != null) {
            // @ts-ignore
            endTimeInput.value = getTimeValueFromDate(endDate);
        }
        new MDCTextField(document.getElementById("text-field-duty-person-" + number + "-end-time"));
        
        const addButton = getElement("button-duty-person-" + number + "-add");
        addClickListener(addButton, () => this.addDutyPerson(number + 1, null, null, null));

        const removeButton = getElement("button-duty-person-" + number + "-remove");
        addClickListener(removeButton, () => this.removeDutyPerson(number));

        this.updateDutyPersonButtons();
    }

    /**
     * @private
     * @param {number} number
     * @returns any
     */
    createDutyPersonViewModel(number) {
        const viewModel = {};
        viewModel.texts = this.createDutyPersonTextsViewModel();
        viewModel.number = number;
        return viewModel;
    }

    /**
     * @private
     * @returns any
     */
    createDutyPersonTextsViewModel() {
        const t = {};
        t.labelPersonName = texts.labelPersonName;
        t.actionAddPerson = texts.actionAddPerson;
        t.actionRemovePerson = texts.actionRemovePerson;
        return t;
    }

    /**
     * @private
     * @param {number} number
     */
    removeDutyPerson(number) {
        getElement("container-duty-person-" + number).remove();
        this.updateDutyPersonButtons();
    }

    /**
     * @private
     */
    updateDutyPersonButtons() {
        var addButton;
        var removeButton;
        for (var container of this.dutyPersonsContainer.childNodes) {
            // @ts-ignore
            const number = container.dataset.dutyPersonNumber;
            addButton = getElement("button-duty-person-" + number + "-add");
            removeButton = getElement("button-duty-person-" + number + "-remove");
            addButton.hidden = true;
            removeButton.hidden = false;
        }
        addButton.hidden = false;
        if (this.dutyPersonsContainer.childElementCount == 1) {
            removeButton.hidden = true;  
        }
    }

    /**
     * @private
     * @param {string} startTime
     */
    updateDutyPersonStartTimes(startTime) {
        var startTimeInput;
        for (var container of this.dutyPersonsContainer.childNodes) {
            // @ts-ignore
            const number = container.dataset.dutyPersonNumber;
            startTimeInput = getElement("text-field-input-duty-person-" + number + "-start-time");
            // @ts-ignore
            startTimeInput.value = startTime;
        }
    }

    /**
     * @private
     * @param {string} endTime
     */
    updateDutyPersonEndTimes(endTime) {
        var endTimeInput;
        for (var container of this.dutyPersonsContainer.childNodes) {
            // @ts-ignore
            const number = container.dataset.dutyPersonNumber;
            endTimeInput = getElement("text-field-input-duty-person-" + number + "-end-time");
            // @ts-ignore
            endTimeInput.value = endTime;
        }
    }

    /**
     * @private
     */
    updateDutyPersonTimeInputs() {
        var timesContainer;
        for (var container of this.dutyPersonsContainer.childNodes) {
            // @ts-ignore
            const number = container.dataset.dutyPersonNumber;
            timesContainer = getElement("container-duty-person-" + number + "-times");
            timesContainer.hidden = !this.individualTimes;
        }
    }

    /**
     * @protected
     * @returns any
     */
    retrieveDutyData() {
        const duty = {};

        this.removeErrorMessage();

        // @ts-ignore
        const typeId = this.dutyTypeSelect.value;
        if (typeId == 0) {
            this.showErrorMessage(ErrorMessage.VALIDATION_DUTY_TYPE_EMPTY);
            return null;
        }
        duty.typeId = parseNumber(typeId);

        // @ts-ignore
        const locationId = this.dutyLocationSelect.value;
        if (locationId == 0) {
            this.showErrorMessage(ErrorMessage.VALIDATION_DUTY_LOCATION_EMPTY);
            return null;
        }
        duty.locationId = parseNumber(locationId);

        // @ts-ignore
        const dateValue = this.dutyDateInput.value;
        if (dateValue == "") {
            this.showErrorMessage(ErrorMessage.VALIDATION_DUTY_DATE_EMPTY);
            return null;
        }
        const date = parseDateValue(dateValue);
        duty.date = toDateString(date);

        const dutyPersons = this.retrieveDutyPersonsData(dateValue);
        if (dutyPersons == null) {
            return;
        }
        duty.persons = dutyPersons;

        // @ts-ignore
        const commentValue = this.dutyCommentInput.value;
        duty.comment = commentValue;

        return duty;
    }

    /**
     * @private
     * @param {string} dateValue
     * @returns any
     */
    retrieveDutyPersonsData(dateValue) {
        const dutyPersons = [];

        for (var dutyPersonContainer of this.dutyPersonsContainer.childNodes) {
            // @ts-ignore
            const number = dutyPersonContainer.dataset.dutyPersonNumber;

            const nameInput = getElement("text-field-input-duty-person-" + number + "-name");
            const startTimeInput = !this.individualTimes ? this.dutyStartTimeInput :
                getElement("text-field-input-duty-person-" + number + "-start-time");
            const endTimeInput = !this.individualTimes ? this.dutyEndTimeInput :
                getElement("text-field-input-duty-person-" + number + "-end-time");

            const dutyPerson = {};

            // @ts-ignore
            const name = nameInput.value;
            if (name == "") {
                this.showErrorMessage(ErrorMessage.VALIDATION_DUTY_PERSON_NAME_EMPTY);
                return null;
            }
            const id = this.getPersonIdByName(name);
            if (id == 0) {
                this.showErrorMessage(ErrorMessage.VALIDATION_DUTY_PERSON_UNKNOWN);
                return;
            }
            dutyPerson.personId = id;

            // @ts-ignore
            const startTimeValue = startTimeInput.value;
            if (startTimeValue == "") {
                this.showErrorMessage(ErrorMessage.VALIDATION_DUTY_START_TIME_EMPTY);
                return null;
            }
            const startDate = parseDateTimeValue(dateValue, startTimeValue);
            dutyPerson.start = toDateString(startDate);

            // @ts-ignore
            const endTimeValue = endTimeInput.value;
            if (endTimeValue == "") {
                this.showErrorMessage(ErrorMessage.VALIDATION_DUTY_END_TIME_EMPTY);
                return null;
            }
            const endDate = parseDateTimeValue(dateValue, endTimeValue);
            dutyPerson.end = toDateString(endDate);

            if (startDate.valueOf() >= endDate.valueOf()) {
                this.showErrorMessage(ErrorMessage.VALIDATION_DUTY_TIME_PERIOD_INVALID);
                return null;
            }

            dutyPersons.push(dutyPerson);
        }

        return dutyPersons;
    }

    /**
     * @private
     * @param {string} name
     * @returns number
     */
    getPersonIdByName(name) {
        for (var person of this.persons.items) {
            if (createPersonName(person) == name) {
                return person.id;
            }
        }
        return 0;
    }

}

// --- Create duty controller ---

class CreateDutyController extends CreateEditDutyController {

    /** 
     * @param {Router} router
     * @param {HTMLElement} container
     */
    constructor(router, container) {
        super(router, container);
    }

    async show() {
        try {
            this.dutyTypes = await this.loadDutyTypes();
            this.dutyLocations = await this.loadDutyLocations();
            this.persons = await this.loadPersons();

            this.dutyTypes.sort(this.compareByPosition);
            this.dutyLocations.sort(this.compareByPosition);

            const viewModel = this.createViewModel(this.dutyTypes, this.dutyLocations, this.persons);

            this.dutyPersonTemplate = await this.loadTemplate("duty-person");

            const template = await this.loadTemplate("create-duty");
            this.renderTemplate(template, viewModel);
            this.initViews();

            this.updateDutyTypes(0);
            this.updateDutyLocations(0, 0);
            this.addDutyPerson(1, null, null, null);
        } catch (e) {
            console.log(e.stack);
            this.handleError(e);
        }
    }

    /**
     * @private
     * @param {any} dutyTypes
     * @param {any} dutyLocations
     * @param {any} persons
     * @returns any
     */
    createViewModel(dutyTypes, dutyLocations, persons) {
        const viewModel = {};
        viewModel.texts = this.createTextsViewModel();
        viewModel.dutyTypes = dutyTypes;
        viewModel.dutyLocations = dutyLocations;
        viewModel.today = getDateValueFromDate(new Date());
        viewModel.persons = this.createPersonsViewModel(persons);
        return viewModel;
    }

    /**
     * @private
     * @returns any
     */
    createTextsViewModel() {
        const t = {};
        t.title = texts.viewTitleDutyCreate;
        t.actionBack = texts.actionBack;
        t.actionCreateDuty = texts.actionCreateDuty;
        t.labelIndividualTimes = texts.labelIndividualTimes;
        t.labelComment = texts.labelComment;
        return t;
    }

    /**
     * @protected
     */
    initViews() {
        new MDCTopAppBar(getElement("app-bar"));

        const backButton = getElement("app-bar-button-back");
        addClickListener(backButton, () => this.router.navigateBack());
        
        const createButton = getElement("app-bar-button-ok");
        addClickListener(createButton, () => this.executeCreateDuty());

        super.initViews();
    }

    /**
     * @private
     */
    async executeCreateDuty() {
        const duty = this.retrieveDutyData();
        if (duty == null) {
            return;
        }

        try {
            await createDuty(getAccessToken(), duty);
            this.handleCreateDutySuccess();
        } catch (e) {
            console.log(e.stack);
            this.handleCreateDutyError(e);
        }
    }
    
    /**
     * @private
     */
    handleCreateDutySuccess() {
        console.info("Create duty success");
        this.router.navigateBack();
    }

    /**
     * @private
     * @param {number} code
     */
    handleCreateDutyError(code) {
        if (code == ResponseCode.ERROR_NETWORK) {
            return;
        }

        console.info("Create duty error: code=" + code);
        const message = getErrorMessageForCode(code);
        this.showErrorMessage(message);
    }

}

// --- View duty controller ---

class ViewDutyController extends DutyController {
    
    /** @type {number} */
    id;

    /** 
     * @param {Router} router
     * @param {HTMLElement} container
     * @param {number} id
     */
    constructor(router, container, id) {
        super(router, container);
        this.id = id;
    }

    async show() {
        try {
            const user = await this.loadCurrentUser();
            const userRoles = await this.loadCurrentUserRoles();
            const duty = await this.loadDuty(this.id);
            const createdBy = await this.loadUser(duty.createdBy);
            const changedBy = await this.loadUser(duty.changedBy);
            const dutyTypes = await this.loadDutyTypes();
            const dutyLocations = await this.loadDutyLocations();
            const persons = await this.loadPersons();

            const viewModel = this.createViewModel(user, userRoles, duty, createdBy, changedBy,
                dutyTypes, dutyLocations, persons);

            const template = await this.loadTemplate("view-duty");
            this.renderTemplate(template, viewModel);
            this.initViews();
        } catch (e) {
            console.log(e.stack);
            this.handleError(e);
        }
    }

    /**
     * @private
     * @param {any} user
     * @param {any} userRoles
     * @param {any} duty
     * @param {any} createdBy
     * @param {any} changedBy
     * @param {any} dutyTypes
     * @param {any} dutyLocations
     * @param {any} persons
     * @returns any
     */
    createViewModel(user, userRoles, duty, createdBy, changedBy, dutyTypes, dutyLocations, persons) {
        const hasUserEditorRole = userRoles.includes("editor") || userRoles.includes("admin");

        const dts = createMap(dutyTypes);
        const dls = createMap(dutyLocations);

        const individualTimes = this.hasDutyIndividualTimes(duty);

        const viewModel = {};
        viewModel.texts = this.createTextsViewModel();
        viewModel.id = duty.id;
        viewModel.dutyType = getObjectFieldValue(dts.get(duty.typeId), "description",
            texts.textUnknown);
        viewModel.dutyLocation = getObjectFieldValue(dls.get(duty.locationId), "description",
            texts.textUnknown);
        viewModel.dutyDate = getReadableDateString(fromDateString(duty.date));
        viewModel.individualTimes = individualTimes;
        if (duty.persons.length > 0) {
            const dutyPerson = duty.persons[0];
            viewModel.dutyStart = getReadableTimeString(fromDateString(dutyPerson.start));
            viewModel.dutyEnd = getReadableTimeString(fromDateString(dutyPerson.end));
        } else {
            viewModel.dutyStart = "";
            viewModel.dutyEnd = "";
        }
        if (duty.createdAt != null) {
            viewModel.createdAt = getReadableDateTimeString(fromDateString(duty.createdAt));
            viewModel.createdBy = getObjectFieldValue(createdBy, "name", texts.textUnknown);
        }
        if (duty.changedAt != null) {
            viewModel.changedAt = getReadableDateTimeString(fromDateString(duty.changedAt));
            viewModel.changedBy = getObjectFieldValue(changedBy, "name", texts.textUnknown);
        }
        viewModel.dutyPersons = this.createDutyPersonsViewModel(duty, persons);
        viewModel.dutyComment = duty.comment;
        viewModel.canBeChanged = hasUserEditorRole || duty.createdBy == user.id;
        return viewModel;
    }

    /**
     * @private
     * @returns any
     */
    createTextsViewModel() {
        const t = {};
        t.title = texts.viewTitleDutyView;
        t.actionBack = texts.actionBack;
        t.actionEditDuty = texts.actionEditDuty;
        t.actionDeleteDuty = texts.actionDeleteDuty;
        t.noPersons = texts.textNoPersons;
        t.dialogTitleDeleteDuty = texts.dialogTitleDeleteDuty;
        t.dialogMessageDeleteDuty = texts.dialogMessageDeleteDuty;
        t.dialogActionDeleteDutyNo = texts.dialogActionDeleteDutyNo;
        t.dialogActionDeleteDutyYes = texts.dialogActionDeleteDutyYes;
        t.noComment = texts.textNoComment;
        return t;
    }

    /**
     * @private
     * @param {any} duty
     * @param {any} persons
     * @returns any
     */
    createDutyPersonsViewModel(duty, persons) {
        const ps = createMap(persons.items);

        const dps = [];
        duty.persons.forEach(dutyPerson => {
            const dp = {};
            const p = ps.get(dutyPerson.personId);
            if (p != null) {
                dp.name = createPersonName(p);
            } else {
                dp.name = texts.textUnknown;
            }
            dp.start = getReadableTimeString(fromDateString(dutyPerson.start));
            dp.end = getReadableTimeString(fromDateString(dutyPerson.end));
            dps.push(dp);
        });
        return dps;
    }

    /**
     * @protected
    */
    initViews() {
        new MDCTopAppBar(getElement("app-bar"));

        const backButton = getElement("app-bar-button-back");
        addClickListener(backButton, () => this.router.navigateBack());

        const deleteDutyDialog = this.createDeleteDutyDialog()
        const deleteDutyButton = getElement("app-bar-button-delete-duty");
        addClickListener(deleteDutyButton, () => {
            deleteDutyButton.blur();
            deleteDutyDialog.open();
        });

        this.errorText = getElement("text-error");
    }

    /**
     * @private
     * @returns any
     */
    createDeleteDutyDialog() {
        const dialog = new MDCDialog(getElement("dialog-delete-duty"));

        const dialogButtonCancel = getElement("dialog-button-delete-duty-cancel");
        addClickListener(dialogButtonCancel, () => {
            dialog.close();
        });
        const dialogButtonOk = getElement("dialog-button-delete-duty-ok");
        addClickListener(dialogButtonOk, () => {
            dialog.close();
            this.executeDeleteDuty();
        });

        return dialog;
    }

    /**
     * @private
     */
    async executeDeleteDuty() {
        try {
            await deleteDuty(getAccessToken(), this.id);
            this.handleDeleteDutySuccess();
        } catch (e) {
            console.log(e.stack);
            this.handleDeleteDutyError(e);
        }
    }
    
    /**
     * @private
     */
    handleDeleteDutySuccess() {
        this.router.navigateBack();
    }

    /**
     * @private
     * @param {number} code
     */
    handleDeleteDutyError(code) {
        if (code == ResponseCode.ERROR_NETWORK) {
            return;
        }

        console.info("Delete duty error: code=" + code);
        const message = getErrorMessageForCode(code);
        this.showErrorMessage(message);
    }

}

// --- Edit duty controller ---

class EditDutyController extends CreateEditDutyController {
    
    /** @type {number} */
    id;

    /** 
     * @param {Router} router
     * @param {HTMLElement} container
     * @param {number} id
     */
    constructor(router, container, id) {
        super(router, container);
        this.id = id;
    }

    async show() {
        try {
            this.duty = await this.loadDuty(this.id);
            this.dutyTypes = await this.loadDutyTypes();
            this.dutyLocations = await this.loadDutyLocations();
            this.persons = await this.loadPersons();

            this.dutyTypes.sort(this.compareByPosition);
            this.dutyLocations.sort(this.compareByPosition);

            this.individualTimes = this.hasDutyIndividualTimes(this.duty);

            const viewModel = this.createViewModel(this.duty, this.dutyTypes, this.dutyLocations,
                this.persons);

            this.dutyPersonTemplate = await this.loadTemplate("duty-person");

            const template = await this.loadTemplate("edit-duty");
            this.renderTemplate(template, viewModel);
            this.initViews();

            this.updateDutyTypes(this.duty.typeId);
            this.updateDutyLocations(this.duty.typeId, this.duty.locationId);
            this.updateDutyTimeInputs();
            this.updateDutyIndividualTimesInput();
            if (this.duty.persons.length > 0) {
                this.addDutyPersons(this.duty.persons, this.persons);
            } else {
                this.addDutyPerson(1, null, null, null);
            }
        } catch (e) {
            console.log(e.stack);
            this.handleError(e);
        }
    }

    /**
     * @private
     * @param {any} duty
     * @param {any} dutyTypes
     * @param {any} dutyLocations
     * @param {any} persons
     * @returns any
     */
    createViewModel(duty, dutyTypes, dutyLocations, persons) {
        const viewModel = {};
        viewModel.texts = this.createTextsViewModel();
        viewModel.duty = this.createDutyViewModel(duty);
        viewModel.dutyTypes = this.createDutyTypesViewModel(dutyTypes, duty.typeId);
        viewModel.dutyLocations = this.createDutyLocationsViewModel(dutyLocations, duty.locationId);
        viewModel.persons = this.createPersonsViewModel(persons);
        return viewModel;
    }

    /**
     * @private
     * @returns any
     */
    createTextsViewModel() {
        const t = {};
        t.title = texts.viewTitleDutyEdit;
        t.actionBack = texts.actionBack;
        t.actionSaveDuty = texts.actionSaveDuty;
        t.labelIndividualTimes = texts.labelIndividualTimes;
        t.labelComment = texts.labelComment;
        return t;
    }

    /**
     * @private
     * @param {any} duty
     * @returns any
     */
    createDutyViewModel(duty) {
        const d = {};
        d.id = duty.id;
        d.typeId = duty.typeId;
        d.locationId = duty.locationId;
        d.date = getDateValueFromDate(fromDateString(duty.date));
        if (duty.persons.length > 0) {
            const dutyPerson = duty.persons[0];
            d.start = getTimeValueFromDate(fromDateString(dutyPerson.start));
            d.end = getTimeValueFromDate(fromDateString(dutyPerson.end));
        } else {
            d.start = "00:00";
            d.end = "00:00";
        }
        d.comment = duty.comment;
        return d;
    }

    /**
     * @private
     * @param {any} dutyTypes
     * @param {number} dutyTypeId
     * @returns any
     */
    createDutyTypesViewModel(dutyTypes, dutyTypeId) {
        const dts = [];
        for (var dutyType of dutyTypes) {
            const dt = {};
            dt.id = dutyType.id;
            dt.description = dutyType.description;
            dt.selected = dutyType.id == dutyTypeId;
            dts.push(dt);
        }
        return dts;
    }

    /**
     * @private
     * @param {any} dutyLocations
     * @param {number} dutyLocationId
     * @returns any
     */
    createDutyLocationsViewModel(dutyLocations, dutyLocationId) {
        const dls = [];
        for (var dutyLocation of dutyLocations) {
            const dl = {};
            dl.id = dutyLocation.id;
            dl.description = dutyLocation.description;
            dl.selected = dutyLocation.id == dutyLocationId;
            dls.push(dl);
        }
        return dls;
    }

    /**
     * @protected
     */
    initViews() {
        new MDCTopAppBar(getElement("app-bar"));

        const backButton = getElement("app-bar-button-back");
        addClickListener(backButton, () => this.router.navigateBack());
        
        const updateButton = getElement("app-bar-button-ok");
        addClickListener(updateButton, () => this.executeUpdateDuty());

        super.initViews();
    }

    /**
     * @private
     * @param {any} dutyPersons
     * @param {any} persons
     */
    addDutyPersons(dutyPersons, persons) {
        const ps = createMap(persons.items);

        var number = 1;
        for (var dutyPerson of dutyPersons) {
            const p = ps.get(dutyPerson.personId);
            const pn = createPersonName(p);
            this.addDutyPerson(number, pn, fromDateString(dutyPerson.start), fromDateString(
                dutyPerson.end));
            number++;
        }
    }
    
    /**
     * @private
     */
    async executeUpdateDuty() {
        const duty = this.retrieveDutyData();
        if (duty == null) {
            return;
        }

        duty.id = this.id;

        try {
            await updateDuty(getAccessToken(), duty);
            this.handleUpdateDutySuccess();
        } catch (e) {
            console.log(e.stack);
            this.handleUpdateDutyError(e);
        }
    }
    
    /**
     * @private
     */
    handleUpdateDutySuccess() {
        console.info("Update duty success");
        this.router.navigateBack();
    }

    /**
     * @private
     * @param {number} code
     */
    handleUpdateDutyError(code) {
        if (code == ResponseCode.ERROR_NETWORK) {
            return;
        }

        console.info("Update duty error: code=" + code);
        const message = getErrorMessageForCode(code);
        this.showErrorMessage(message);
    }

}

// --- Helper functions ---

/**
 * @param {string} id
 * @returns HTMLElement
 */
function getElement(id) {
    return document.getElementById(id);
}

/**
 * @param {HTMLElement} element
 * @param {string} html
 */
function setHtml(element, html) {
    if (element != null) {
        element.innerHTML = html;
    }
}

/**
 * @param {HTMLElement} element
 * @param {string} html
 */
function appendHtml(element, html) {
    if (element != null) {
        element.insertAdjacentHTML("beforeend", html);
    }
}

/**
 * @param {HTMLElement} element
 */
function removeAllChilds(element) {
    while (element.firstChild) {
        element.firstChild.remove();
    }
}

/**
 * @param {HTMLElement} select
 * @param {string} value
 * @param {string} text
 * @param {boolean} selected
 */
function addSelectOption(select, value, text, selected) {
    var option = document.createElement("option");
    option.setAttribute("value", value);
    if (selected) {
        option.setAttribute("selected", "");
    }
    option.appendChild(document.createTextNode(text));
    select.appendChild(option);
}

/**
 * @param {HTMLElement} element
 * @param {function} listener
 */
function addClickListener(element, listener) {
    element.addEventListener("click", (event) => {
        event.preventDefault();
        event.stopPropagation();
        listener();
    });
}

/**
 * @param {HTMLElement} element
 * @param {function} listener
 */
function addChangeListener(element, listener) {
    element.addEventListener("change", () => {
        listener();
    });
}

/**
 * @param {MDCList} list
 * @param {function} listener
 */
function addListItemClickListener(list, listener) {
    list.listen("click", (event) => {
        const element = event.target;
        // @ts-ignore
        const isAction = element.classList.contains("mdc-list-item__meta");
        if (!isAction) {
            // @ts-ignore
            listener(getListItemId(element));
        }
    });
}

/**
 * @param {MDCList} list
 * @param {function} listener
 */
function addListItemActionClickListener(list, listener) {
    list.listen("click", (event) => {
        const element = event.target;
        // @ts-ignore
        const isAction = element.classList.contains("mdc-list-item__meta");
        if (isAction) {
            // @ts-ignore
            listener(getListItemId(element));
        }
    });
}

/**
 * @param {HTMLElement} element
 * @return number
 */
function getListItemId(element) {
    while (!element.classList.contains("mdc-list-item")) {
        element = element.parentElement;
    }
    return element.dataset.id;
}

/**
 * @param {any} objects
 * @returns any
 */
function createMap(objects) {
    return new Map(objects.map(o => [o.id, o]));
}

/**
 * @param {any} object
 * @param {any} field
 * @param {any} fallback
 * @returns any
 */
function getObjectFieldValue(object, field, fallback) {
    const value = object[field];
    return value != null ? value : fallback;
}

/**
 * @param {any} person
 * @returns string
 **/
function createPersonName(person) {
    const title = person.title != "" ? person.title + " " : "";
    const name = person.firstName + " " + person.lastName;
    const suffix = person.suffix != 0 ? " " + person.suffix : "";
    return title + name + suffix;
}

/**
 * @param {string} dateValue
 * @returns Date
 **/
function parseDateValue(dateValue) {
    const dateValues = dateValue.split("-");
    return new Date(parseNumber(dateValues[0]), parseNumber(dateValues[1]) - 1, parseNumber(
        dateValues[2]));
}

/**
 * @param {string} dateValue
 * @param {string} timeValue
 * @returns Date
 **/
function parseDateTimeValue(dateValue, timeValue) {
    const dateValues = dateValue.split("-");
    const timeValues = timeValue.split(":");
    return new Date(parseNumber(dateValues[0]), parseNumber(dateValues[1]), parseNumber(
        dateValues[2]), parseNumber(timeValues[0]), parseNumber(timeValues[1]));
}

/**
 * @param {Date} date
 * @returns string
 **/
function getDateValueFromDate(date) {
    return formatNumber(date.getFullYear(), 4) + "-" + formatNumber(date.getMonth() + 1, 2) + "-" +
        formatNumber(date.getDate(), 2);
}

/**
 * @param {Date} date
 * @returns string
 **/
function getTimeValueFromDate(date) {
    return formatNumber(date.getHours(), 2) + ":" + formatNumber(date.getMinutes(), 2);
}

// --- Exports ---

export {Controller, LoginController, ListDutiesController, CreateDutyController, ViewDutyController,
    EditDutyController};