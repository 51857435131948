import Navigo from "navigo";

import {parseNumber, getAccessToken} from "./util";
import {Controller, LoginController, ListDutiesController, CreateDutyController, ViewDutyController,
    EditDutyController} from "./controller";

class Router extends Navigo {

    constructor() {
        super(null, true);
    }

    navigateLogin() {
        this.navigate("login");
    }

    navigateListDuties() {
        router.navigate("list");
    }

    navigateCreateDuty() {
        router.navigate("create");
    }

    /**
     * @param {number} id 
     */
    navigateViewDuty(id) {
        router.navigate("view/" + id);
    }

    /**
     * @param {number} id 
     */
    navigateEditDuty(id) {
        router.navigate("edit/" + id);
    }

    navigateBack() {
        window.history.back();
    }

}

var container;

var router;

function init() {
    container = document.getElementById("container-app");

    router = new Router();
    router
        .on({
            "login": function() {
                showIfNotAuthenticated(texts.pageTitleLogin, new LoginController(router, container));
            },
            "list": function() {
                showIfAuthenticated(texts.pageTitleDutyList, new ListDutiesController(router,
                    container));
            },
            "create": function() {
                showIfAuthenticated(texts.pageTitleDutyCreate, new CreateDutyController(router,
                    container));
            },
            "view/:id": function(params) {
                const id = parseNumber(params.id);
                showIfAuthenticated(texts.pageTitleDutyView, new ViewDutyController(router,
                    container, id));
            },
            "edit/:id": function(params) {
                const id = parseNumber(params.id);
                showIfAuthenticated(texts.pageTitleDutyEdit, new EditDutyController(router,
                    container, id));
            },
            "*": function() {
                if (!isAuthenticated()) {
                    router.navigateLogin();
                } else {
                    router.navigateListDuties(0);
                }
            }
        })
        .resolve();
}

/**
 * @returns boolean
 */
function isAuthenticated() {
    return getAccessToken() != null;
}

/**
 * @param {string} title
 * @param {Controller} controller 
 */
function showIfNotAuthenticated(title, controller) {
    if (!isAuthenticated()) {
        setTitle(title);
        controller.show();
    } else {
        router.navigateListDuties(0);
    }
}

/**
 * @param {string} title
 * @param {Controller} controller 
 */
function showIfAuthenticated(title, controller) {
    if (isAuthenticated()) {
        setTitle(title);
        controller.show();
    } else {
        router.navigateLogin();
    }
}

/**
 * @private
 * @param {string} title
 */
function setTitle(title) {
    if (title != "") {
        document.title = texts.appName + " - " + title;
    } else {
        document.title = texts.appName;
    }
}

document.addEventListener("DOMContentLoaded", function() {
    init();
});

export {Router};