import {ApiResponseCode} from "./api";
import {ResponseCode} from "./const";

const ErrorMessage = {
    UNKNOWN:                              texts.error,

    NETWORK:                              texts.errorNetwork,

    AUTH_UNKNOWN:                         texts.errorAuthUnknown,
    AUTH_UNAUTHORIZED:                    texts.errorAuthUnauthorized,

    PERMISSION_UNKNOWN:                   texts.errorPermissionUnknown,
	PERMISSION_VIEW_DUTIES:               texts.errorPermissionViewDuties,
	PERMISSION_CREATE_DUTY:               texts.errorPermissionCreateDuty,
	PERMISSION_CHANGE_DUTY:               texts.errorPermissionChangeDuty,

    VALIDATION_UNKNOWN:                   texts.errorUnknown,
    VALIDATION_DUTY_TYPE_EMPTY:           texts.errorValidationDutyTypeEmpty,
    VALIDATION_DUTY_LOCATION_EMPTY:       texts.errorValidationDutyLocationEmpty,
    VALIDATION_DUTY_DATE_EMPTY:           texts.errorValidationDutyDateEmpty,
    VALIDATION_DUTY_START_TIME_EMPTY:     texts.errorValidationDutyStartTimeEmpty,
    VALIDATION_DUTY_END_TIME_EMPTY:       texts.errorValidationDutyEndTimeEmpty,
    VALIDATION_DUTY_TIME_PERIOD_INVALID:  texts.errorValidationDutyTimePeriodInvalid,
    VALIDATION_DUTY_TIME_PERIODS_OVERLAP: texts.errorValidationDutyTimePeriodsOverlap,
    VALIDATION_DUTY_PERSON_NAME_EMPTY:    texts.errorValidationDutyPersonNameEmpty,
    VALIDATION_DUTY_PERSON_UNKNOWN:       texts.errorValidationDutyPersonUnknown,

    LOGIC_UNKNOWN:                        texts.errorUnknown,
    LOGIC_DUTY_NOT_FOUND:                 texts.errorLogicDutyNotFound,
    LOGIC_DUTY_ALREADY_DELETED:           texts.errorLogicDutyAlreadyDeleted
};

/**
 * @param {number} code
 * @returns string
 */
function getErrorMessageForCode(code) {
    if (code == ResponseCode.ERROR_UNKNOWN) {
        return ErrorMessage.UNKNOWN;
    } else if (code == ResponseCode.ERROR_NETWORK) {
        return ErrorMessage.NETWORK;
    }
    
    if (code <= ApiResponseCode.ERROR_AUTH_UNKNOWN &&
        code > ApiResponseCode.ERROR_PERMISSION_UNKNOWN) {
        return getAuthErrorMessageForCode(code);
    } else if (code <= ApiResponseCode.ERROR_PERMISSION_UNKNOWN &&
        code > ApiResponseCode.ERROR_VALIDATION_UNKNOWN) {
        return getPermissionErrorMessageForCode(code);
    } else if (code <= ApiResponseCode.ERROR_VALIDATION_UNKNOWN &&
        code > ApiResponseCode.ERROR_LOGIC_UNKNOWN) {
        return getValidationErrorMessageForCode(code);
    } else if (code <= ApiResponseCode.ERROR_LOGIC_UNKNOWN &&
        code > ApiResponseCode.ERROR_SYSTEM_UNKNOWN) {
        return getLogicErrorMessageForCode(code);
    } else {
        return ErrorMessage.UNKNOWN;
    }
}

/**
 * @param {number} code
 * @returns string
 */
function getAuthErrorMessageForCode(code) {
    switch (code) {
        case ApiResponseCode.ERROR_AUTH_UNAUTHORIZED:
            return ErrorMessage.AUTH_UNAUTHORIZED;
        default:
            return ErrorMessage.AUTH_UNKNOWN;
    };
}

/**
 * @param {number} code
 * @returns string
 */
function getPermissionErrorMessageForCode(code) {
    switch (code) {
        case ApiResponseCode.ERROR_PERMISSION_READ_ALL_DUTIES ||
            ApiResponseCode.ERROR_PERMISSION_READ_OWN_DUTIES:
            return ErrorMessage.PERMISSION_VIEW_DUTIES;
        case ApiResponseCode.ERROR_PERMISSION_CREATE_DUTIES:
            return ErrorMessage.PERMISSION_CREATE_DUTY;
        case ApiResponseCode.ERROR_PERMISSION_CHANGE_ALL_DUTIES ||
            ApiResponseCode.ERROR_PERMISSION_CHANGE_OWN_DUTIES:
            return ErrorMessage.PERMISSION_CHANGE_DUTY;
        default:
            return ErrorMessage.PERMISSION_UNKNOWN;
    }
}

/**
 * @param {number} code
 * @returns string
 */
function getValidationErrorMessageForCode(code) {
    switch (code) {
        case ApiResponseCode.ERROR_VALIDATION_TIME_PERIODS_OVERLAP:
            return ErrorMessage.VALIDATION_DUTY_TIME_PERIODS_OVERLAP;
        // TODO!!!
        default:
            return ErrorMessage.VALIDATION_UNKNOWN;
    }
}

/**
 * @param {number} code
 * @returns string
 */
function getLogicErrorMessageForCode(code) {
    switch (code) {
        case ApiResponseCode.ERROR_LOGIC_DUTY_NOT_FOUND:
            return ErrorMessage.LOGIC_DUTY_NOT_FOUND;
        case ApiResponseCode.ERROR_LOGIC_DUTY_ALREADY_DELETED:
            return ErrorMessage.LOGIC_DUTY_ALREADY_DELETED;
        //  TODO!!!
        default:
            return ErrorMessage.VALIDATION_UNKNOWN;
    }
}

export {ErrorMessage, getErrorMessageForCode};