/**
 * @param {string} s
 * @returns number
 */
function parseNumber(s) {
    const parsed = parseInt(s, 10);
    if (isNaN(parsed)) {
        console.error("Could not parse number '" + s +"'.");
        return 0;
    }
    return parsed;
}

/**
 * @param {number} n
 * @param {number} size
 * @returns string
 **/
function formatNumber(n, size) {
    var s = n + "";
    while (s.length < size) s = "0" + s;
    return s;
}

/**
 * @param {string} s
 * @returns object
 */
function fromJson(s) {
    try {
        return JSON.parse(s);
    } catch (e) {
        console.error("Could not read JSON. (" + e + ")");
        return null;
    }
}

/**
 * @param {object} o
 * @returns string
 */
function toJson(o) {
    try {
        return JSON.stringify(o);
    } catch (e) {
        console.error("Could not write JSON. (" + e + ")");
        return null;
    }
}

/**
 * @param {string} key
 * @returns object
 */
function readLsItem(key) {
    return localStorage != null ? localStorage.getItem(key) : null;
}

/**
 * @param {string} key
 * @param {object} value
 */
function writeLsItem(key, value) {
    if (localStorage != null) {
        localStorage.setItem(key, value);
    }
}

/**
 * @param {string} key
 */
function deleteLsItem(key) {
    if (localStorage != null) {
        localStorage.removeItem(key);
    }
}

/**
 * @returns string
 */
function getAccessToken() {
    return readLsItem("token");
}

/**
 * @param {string} accessToken
 */
function setAccessToken(accessToken) {
    writeLsItem("token", accessToken);
}

function removeAccessToken() {
    deleteLsItem("token");
}

/**
 * @param {string} date
 * @returns Date
 */
function fromDateString(date) {
    return new Date(Date.parse(date));
}

/**
 * @param {Date} date
 * @returns string
 */
function toDateString(date) {
    return date.toJSON();
}

/**
 * @param {Date} date
 * @returns string
 */
function getReadableDateString(date) {
    const options = {year: "numeric", month: "long", day: "numeric"};
    return date.toLocaleDateString(config.locale, options);
}

/**
 * @param {Date} date
 * @returns string
 */
function getReadableTimeString(date) {
    const options = {hour: "2-digit", minute: "2-digit"};
    return date.toLocaleString(config.locale, options);
}

/**
 * @param {Date} date
 * @returns string
 */
function getReadableDateTimeString(date) {
    return getReadableDateString(date) + ", " + getReadableTimeString(date);
}

export {parseNumber, formatNumber, fromJson, toJson, getAccessToken, setAccessToken, removeAccessToken,
    fromDateString, toDateString, getReadableDateString, getReadableTimeString,
    getReadableDateTimeString};
