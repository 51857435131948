import HttpStatus from "http-status-codes";

import {ResponseCode} from "./const";
import {fromJson, toJson} from "./util";

class ApiResponse {

    /** 
     * @param {number} responseCode
     * @param {object} response
     */
    constructor(responseCode, response) {
        this.responseCode = responseCode;
        this.response = response;
    }

}

const ApiResponseCode = {
    SUCCESS:                               ResponseCode.SUCCESS,

    ERROR_UNKNOWN:                         ResponseCode.ERROR_UNKNOWN,

    ERROR_NETWORK:                         ResponseCode.ERROR_NETWORK,

    ERROR_AUTH_UNKNOWN:                    -100,
    ERROR_AUTH_INVALID:                    -101,
	ERROR_AUTH_INVALID_CLIENT:             -102,
	ERROR_AUTH_INVALID_GRANT:              -103,
	ERROR_AUTH_INVALID_SCOPE:              -104,
	ERROR_AUTH_UNSUPPORTED_GRANT_TYPE:     -105,
	ERROR_AUTH_UNAUTHORIZED_CLIENT:        -106,
	ERROR_AUTH_UNAUTHORIZED:               -107,
	ERROR_AUTH_ACCESS_DENIED:              -108,
    
    ERROR_PERMISSION_UNKNOWN:              -200,
    ERROR_PERMISSION_CHANGE_CONFIG:        -201,
	ERROR_PERMISSION_READ_USER_DATA:       -202,
	ERROR_PERMISSION_READ_BASE_DATA:       -203,
	ERROR_PERMISSION_CREATE_DUTIES:        -204,
	ERROR_PERMISSION_READ_OWN_DUTIES:      -205,
	ERROR_PERMISSION_CHANGE_OWN_DUTIES:    -206,
	ERROR_PERMISSION_READ_ALL_DUTIES:      -207,
	ERROR_PERMISSION_CHANGE_ALL_DUTIES:    -208,

    ERROR_VALIDATION_UNKNOWN:                 -300,
    ERROR_VALIDATION_JSON_INVALID:            -301,
	ERROR_VALIDATION_ID_INVALID:              -302,
	ERROR_VALIDATION_FILTER_INVALID:          -303,
    ERROR_VALIDATION_SORT_INVALID:            -304,
	ERROR_VALIDATION_OFFSET_INVALID:          -305,
	ERROR_VALIDATION_LIMIT_INVALID:           -306,
	ERROR_VALIDATION_NUMBER_NEGATIVE:         -307,
	ERROR_VALIDATION_NUMBER_NEGATIVE_OR_ZERO: -308,
	ERROR_VALIDATION_STRING_EMPTY:            -309,
	ERROR_VALIDATION_STRING_TOO_LONG:         -310,
	ERROR_VALIDATION_ARRAY_EMPTY:             -311,
    ERROR_VALIDATION_COLOR_INVALID:           -312,
	ERROR_VALIDATION_USERNAME_INVALID:        -313,
	ERROR_VALIDATION_PASSWORD_INVALID:        -314,
	ERROR_VALIDATION_TIME_PERIOD_INVALID:     -315,
    ERROR_VALIDATION_TIME_PERIODS_OVERLAP:    -316,

    ERROR_LOGIC_UNKNOWN:                          -400,
    ERROR_LOGIC_DUTY_NOT_FOUND:                   -401,
    ERROR_LOGIC_DUTY_ALREADY_DELETED:             -402,
    ERROR_LOGIC_DUTY_TYPE_NOT_FOUND:              -403,
    ERROR_LOGIC_DUTY_TYPE_ALREADY_EXISTS:         -404,
	ERROR_LOGIC_DUTY_TYPE_DELETE_NOT_ALLOWED:     -405,
    ERROR_LOGIC_DUTY_LOCATION_NOT_FOUND:          -406,
    ERROR_LOGIC_DUTY_LOCATION_ALREADY_EXISTS:     -407,
    ERROR_LOGIC_DUTY_LOCATION_DELETE_NOT_ALLOWED: -408,
    ERROR_LOGIC_DUTY_LOCATION_NOT_ALLOWED:        -409,
    ERROR_LOGIC_PERSON_NOT_FOUND:                 -410,
    ERROR_LOGIC_PERSON_ALREADY_EXISTS:            -411,
	ERROR_LOGIC_PERSON_TYPE_NOT_FOUND:            -412,
	ERROR_LOGIC_PERSON_TYPE_ALREADY_EXISTS:       -413,
	ERROR_LOGIC_PERSON_STATE_NOT_FOUND:           -414,
	ERROR_LOGIC_PERSON_STATE_ALREADY_EXISTS:      -415,
	ERROR_LOGIC_ROLE_NOT_FOUND:                   -416,
    ERROR_LOGIC_USER_NOT_FOUND:                   -417,
    ERROR_LOGIC_USER_ALREADY_EXISTS:              -418,
	ERROR_LOGIC_ADMIN_USER_CHANGE_NOT_ALLOWED:    -419,
    ERROR_LOGIC_ADMIN_USER_DELETE_NOT_ALLOWED:    -420,
    
    ERROR_SYSTEM_UNKNOWN:                         -500
};

/**
 * @callback responseParser
 * @param {number} status
 * @param {string} text
 */

// --- Auth functions ---

/**
 * @param {string} username
 * @param {string} password
 * @returns Promise<any>
 */
function login(username, password) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", buildAuthUrl("token"));
        setClientAuthHeader(xhr);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        registerRequestEventHandlers(xhr, parseLoginResonse, resolve, reject);

        const data = "grant_type=password&username=" + username + "&password=" + password;
        xhr.send(data);
    });
}

/** 
 * @param {number} status
 * @param {string} text
 * @returns {ApiResponse}
 */
function parseLoginResonse(status, text) {
    const r = fromJson(text);

    if (status == HttpStatus.OK) {
        const token = r.access_token;
        return new ApiResponse(ApiResponseCode.SUCCESS, token);
    }
    
    const re = getAuthErrorFromResponse(r);

    if (status == HttpStatus.BAD_REQUEST) {
        return new ApiResponse(ApiResponseCode.ERROR_AUTH_UNKNOWN, null);
    } else if (status == HttpStatus.UNAUTHORIZED) {
        if (re == "unauthorized") {
            return new ApiResponse(ApiResponseCode.ERROR_AUTH_UNAUTHORIZED, null);
        } else {
            return new ApiResponse(ApiResponseCode.ERROR_AUTH_UNKNOWN, null);
        }
    } else {
        return new ApiResponse(ApiResponseCode.ERROR_UNKNOWN, null);
    }
}

/** 
 * @param {any} response
 * @returns {String}
 */
function getAuthErrorFromResponse(response) {
    return response != null && response.error ? response.error : "";
}

/**
 * @param {string} token
 * @returns Promise<any>
 */
function logout(token) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", buildAuthUrl("revoke"));
        setClientAuthHeader(xhr);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        registerRequestEventHandlers(xhr, parseLogoutResonse, resolve, reject);

        const data = "token=" + token;
        xhr.send(data);
    });
}

/** 
 * @param {number} status
 * @returns {ApiResponse}
 */
function parseLogoutResonse(status) {
    if (status == HttpStatus.NO_CONTENT) {
        return new ApiResponse(ApiResponseCode.SUCCESS, null);
    } else {
        return new ApiResponse(ApiResponseCode.ERROR_UNKNOWN, null);
    }
}

// --- User functions ---

/**
 * @param {string} token
 * @returns Promise<any>
 */
function getCurrentUser(token) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", buildApiUrl("user"));
        setTokenAuthHeader(xhr, token);
        registerRequestEventHandlers(xhr, parseResonse, resolve, reject);
        xhr.send();
    });
}

/**
 * @param {string} token
 * @returns Promise<any>
 */
function getCurrentUserRoles(token) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", buildApiUrl("user/roles"));
        setTokenAuthHeader(xhr, token);
        registerRequestEventHandlers(xhr, parseResonse, resolve, reject);
        xhr.send();
    });
}

/**
 * @param {string} token
 * @param {number} userId
 * @returns Promise<any>
 */
function getUser(token, userId) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", buildApiUrl("users/" + userId));
        setTokenAuthHeader(xhr, token);
        registerRequestEventHandlers(xhr, parseResonse, resolve, reject);
        xhr.send();
    });
}

// --- Person functions ---

/**
 * @param {string} token
 * @returns Promise<any>
 */
function getPersons(token) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", buildApiUrl("persons"));
        setTokenAuthHeader(xhr, token);
        registerRequestEventHandlers(xhr, parseResonse, resolve, reject);
        xhr.send();
    });
}

/**
 * @param {string} token
 * @returns Promise<any>
 */
function getPersonTypes(token) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", buildApiUrl("person_types"));
        setTokenAuthHeader(xhr, token);
        registerRequestEventHandlers(xhr, parseResonse, resolve, reject);
        xhr.send();
    });
}

/**
 * @param {string} token
 * @returns Promise<any>
 */
function getPersonStates(token) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", buildApiUrl("person_states"));
        setTokenAuthHeader(xhr, token);
        registerRequestEventHandlers(xhr, parseResonse, resolve, reject);
        xhr.send();
    });
}

// --- Duty functions ---

/**
 * @param {string} token
 * @param {number} offset
 * @param {number} limit
 * @returns Promise<any>
 */
function getDuties(token, offset, limit) {
    return new Promise(function (resolve, reject) {
        const filters = [{name: "deletedAt", op: "eq", value: "null"}];
        const sort = {name: "date", op: "desc"};

        const xhr = new XMLHttpRequest();
        xhr.open("GET", buildApiUrl("duties") + "?filter=" + buildFilterString(filters) +
            "&sort=" + buildSortString(sort) + "&offset=" + offset + "&limit=" + limit);
        setTokenAuthHeader(xhr, token);
        registerRequestEventHandlers(xhr, parseResonse, resolve, reject);
        xhr.send();
    });
}

/**
 * @param {string} token
 * @param {object} duty
 * @returns Promise<any>
 */
function createDuty(token, duty) {
    return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
        xhr.open("POST", buildApiUrl("duties"));
        setTokenAuthHeader(xhr, token);
        xhr.setRequestHeader("Content-Type", "application/json");
        registerRequestEventHandlers(xhr, parseResonse, resolve, reject);

        const data = toJson(duty);
        xhr.send(data);
    });
}

/**
 * @param {string} token
 * @param {number} dutyId
 * @returns Promise<any>
 */
function getDuty(token, dutyId) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", buildApiUrl("duties/") + dutyId);
        setTokenAuthHeader(xhr, token);
        registerRequestEventHandlers(xhr, parseResonse, resolve, reject);
        xhr.send();
    });
}

/**
 * @param {string} token
 * @param {object} duty
 * @returns Promise<any>
 */
function updateDuty(token, duty) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", buildApiUrl("duties/") + duty.id);
        setTokenAuthHeader(xhr, token);
        xhr.setRequestHeader("Content-Type", "application/json");
        registerRequestEventHandlers(xhr, parseResonse, resolve, reject);

        const data = toJson(duty);
        xhr.send(data);
    });
}

/**
 * @param {string} token
 * @param {number} dutyId
 * @returns Promise<any>
 */
function deleteDuty(token, dutyId) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("DELETE", buildApiUrl("duties/") + dutyId);
        setTokenAuthHeader(xhr, token);
        registerRequestEventHandlers(xhr, parseResonse, resolve, reject);
        xhr.send();
    });
}

/**
 * @param {string} token
 * @returns Promise<any>
 */
function getDutyTypes(token) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", buildApiUrl("duty_types"));
        setTokenAuthHeader(xhr, token);
        registerRequestEventHandlers(xhr, parseResonse, resolve, reject);
        xhr.send();
    });
}

/**
 * @param {string} token
 * @returns Promise<any>
 */
function getDutyLocations(token) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", buildApiUrl("duty_locations"));
        setTokenAuthHeader(xhr, token);
        registerRequestEventHandlers(xhr, parseResonse, resolve, reject);
        xhr.send();
    });
}

// --- Report functions ---

/**
 * @param {string} token
 * @param {number} year
 * @returns Promise<any>
 */
function downloadReport(token, year) {
    return new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", buildApiUrl("exports/reports/" + year));
        setTokenAuthHeader(xhr, token);
        registerDownloadRequestEventHandlers(xhr, resolve, reject);
        xhr.responseType = "blob";
        xhr.send();
    });
}

// --- Helper functions ---

/** 
 * @param {string} path
 * @returns {string}
 */
function buildAuthUrl(path) {
    return config.serverUrl + "/oauth/" + path;
}

/** 
 * @param {string} path
 * @returns {string}
 */
function buildApiUrl(path) {
    return config.serverUrl + "/api/v1/" + path;
}

/** 
 * @param {any[]} filters
 * @returns {string}
 */
function buildFilterString(filters) {
    const fs = [];
    for (var filter of filters) {
        const f = filter.name + "%3B" + filter.op + "%3B" + filter.value;
        fs.push(f);
    }
    return fs.join("%7C");
}

/** 
 * @param {any} sort
 * @returns {string}
 */
function buildSortString(sort) {
    return sort.name + "%3B" + sort.op;
}

/** 
 * @param {XMLHttpRequest} xhr
 */
function setClientAuthHeader(xhr) {
    xhr.setRequestHeader("Authorization", "Basic " + btoa(config.clientId + ":" + config.clientSecret));
}

/** 
 * @param {XMLHttpRequest} xhr
 * @param {string} token
 */
function setTokenAuthHeader(xhr, token) {
    xhr.setRequestHeader("Authorization", "Bearer " + token);
}

/** 
 * @param {XMLHttpRequest} xhr
 * @param {responseParser} parser
 * @param {any} resolve
 * @param {any} reject
 */
function registerRequestEventHandlers(xhr, parser, resolve, reject) {
    xhr.onload = function() {
        if (xhr.readyState == 4 && xhr.status >= 0) {
            const response = parser(xhr.status, xhr.responseText);
            if (response.responseCode == ApiResponseCode.SUCCESS) {
                resolve(response.response);
            } else {
                reject(response.responseCode);
            }
        }
    };
    xhr.onerror = function() {
        console.debug("Network communication failed.");
        reject(ApiResponseCode.ERROR_NETWORK);
    };
}

/** 
 * @param {XMLHttpRequest} xhr
 * @param {any} resolve
 * @param {any} reject
 */
function registerDownloadRequestEventHandlers(xhr, resolve, reject) {
    xhr.onload = function() {
        if (xhr.readyState == 4 && xhr.status >= 0) {
            switch (xhr.status) {
                case 200:
                    resolve(xhr.response);
                    break;
                case 401:
                    reject(ApiResponseCode.ERROR_AUTH_UNAUTHORIZED);
                    break;
                case 403:
                    reject(ApiResponseCode.ERROR_PERMISSION_UNKNOWN);
                    break;
                default:
                    reject(ApiResponseCode.ERROR_UNKNOWN);
            }
        }
    };
    xhr.onerror = function() {
        console.debug("Network communication failed.");
        reject(ApiResponseCode.ERROR_NETWORK);
    };
}

/** 
 * @param {number} status
 * @param {string} text
 * @returns {ApiResponse}
 */
function parseResonse(status, text) {
    const r = text != "" ? fromJson(text) : null;

    if (status == HttpStatus.OK || status == HttpStatus.NO_CONTENT) {
        return new ApiResponse(ApiResponseCode.SUCCESS, r);
    }
    
    const rec = getApiErrorCodeFromResponse(r);

    return new ApiResponse(rec, null);
}

/** 
 * @param {any} response
 * @returns {number}
 */
function getApiErrorCodeFromResponse(response) {
    return response != null && response.code ? response.code : 0;
}

// --- Exports ---

export {ApiResponseCode, login, logout, getCurrentUser, getCurrentUserRoles, getUser, getPersons,
    getPersonTypes, getPersonStates, getDuties, createDuty, getDuty, updateDuty, deleteDuty,
    getDutyTypes, getDutyLocations, downloadReport};